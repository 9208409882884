<template>
  <b-card-code title="Assign Dial-Up Info Update">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="justify-content-center">
          <b-col
            v-if="this.userData"
            lg="6"
            md="6"
            sm="6"
          >
            <b-card
              align="center"
              bg-variant="transparent"
              border-variant="success"
              class="shadow-none"
              text-variant="black"
              title="User Information "
            >
              <b-card-text>
                <b-list-group v-if="this.userData">
                  <b-list-group-item
                    v-if="this.userData.name"
                  >Name : {{ this.userData.name }} (Pair Code:
                    {{ this.userData.pair_code }})</b-list-group-item>
                  <b-list-group-item
                    v-if="this.userData.mobile"
                  >Mobile : {{ this.userData.mobile }}</b-list-group-item>
                  <b-list-group-item
                    v-if="this.userData.email"
                  >Email : {{ this.userData.email }}</b-list-group-item>
                  <b-list-group-item
                    v-if="
                      this.userData.others !== null || this.userData.address
                    "
                  >
                    Address :
                    {{
                      this.userData.address
                        ? this.userData.address
                        : this.userData.others &&
                          this.userData.others[0].address
                          ? this.userData.others[0].address
                          : null
                    }}
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <input
            v-model="usernameInfo.user_id"
            type="hidden"
            class="form-control"
          >
          <b-col md="6">
            <b-form-group
              label="Package Name"
              label-for="package_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Package Name"
                rules="required"
              >
                <v-select
                  v-model="usernameInfo.package_id"
                  :options="package_list"
                  :reduce="(val) => val.value"
                  placeholder="Select Package"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  @input="
                    (packageData) => changePackage(package_list, packageData)
                  "
                />
                <!--                                <v-select
                                    v-model="usernameInfo.package_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="package_list"
                                    :reduce="val => val.value"
                                    :clearable="false"
                                    input-id="user-role"
                                    placeholder="Select Package"
                                />-->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Monthly Bill"
              label-for="package_price"
            >
              <validation-provider
                #default="{ errors }"
                name="package_price"
                rules="required"
              >
                <b-form-input
                  v-model="usernameInfo.package_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Monthly Bill"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Router Name"
              label-for="router_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Router Name"
                rules="required"
              >
                <v-select
                  v-model="usernameInfo.router_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="router_list"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-role"
                  placeholder="Select Router"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="PPP Secrets Name"
              label-for="user_name"
            >
              <validation-provider
                #default="{ errors }"
                name="user_name"
                rules="required"
              >
                <b-form-input
                  v-model="usernameInfo.user_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter PPP Secrets Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Connection Nick Name"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  v-model="usernameInfo.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Connection Nick Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Status"
              label-for="status"
            >
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-select
                  v-model="usernameInfo.status_value"
                  :options="options_status"
                  value-field="value"
                  text-field="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Auto Generate"
              label-for="autogenerate"
            >
              <validation-provider
                #default="{ errors }"
                name="autogenerate"
                rules=""
              >
                <b-form-checkbox
                  v-model="usernameInfo.autogenerate"
                  value="1"
                >
                  Automatic Invoice
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger mr-2"
              @click="confirmDeleteUserNames"
            >
              Delete
            </b-button>
          </b-col>
        </b-row>
        <hr>

        <b-row>
          <b-col cols="12">
            <h6 class="my-3 text-muted">
              Create New Invoice
            </h6>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Invoice Date"
              label-for="invoice_date"
            >
              <validation-provider
                #default="{ errors }"
                name="invoice_date"
                rules=""
              >
                <b-form-datepicker
                  id="invoice_date"
                  v-model="usernameInfo.invoice_date"
                  class="mb-1"
                  :disabled="autogenerateDisable"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Invoice Due Date"
              label-for="next_due_date"
            >
              <validation-provider
                #default="{ errors }"
                name="next_due_date"
                rules=""
              >
                <b-form-datepicker
                  id="next_due_date"
                  v-model="usernameInfo.next_due_date"
                  class="mb-1"
                  :disabled="autogenerateDisable"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3
              v-if="usernameInfo.invoice_statu==0"
              class="text-danger"
            >
              You are not able to make invoice , please contact with admin
            </h3>
            <b-button
              v-if="usernameInfo.invoice_status"
              variant="success mr-2"
              type="submit"
              :disabled="autogenerateDisable"
              @click.prevent="createNewInvoice"
            >
              Make New Invoice
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BCardGroup,
  BCard,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import {
  ISP_PACKAGE_LIST,
  ISP_ROUTER_LIST,
  USERNAME_VIEW,
} from '@core/services/api'
import vSelect from 'vue-select'
import router from '@/router'
import Mixin from '@core/services/mixin'
import { checkRes } from '@core/services/helper'
import $ from 'jquery'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormDatepicker,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSelect,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCardGroup,
    BCard,
    BListGroup,
    BListGroupItem,
  },
  mixins: [Mixin],
  data() {
    return {
      usernameId: router.currentRoute.params.usernameId,
      userData: '',
      usernameInfo: '',
      package_list: [],
      user_list: [],
      router_list: [],
      emailValue: '',
      name: '',
      required,
      invoice_date: null,
      next_due_date: null,
      autogenerate: null,
      selected: [1, 0],
      email,
      status_value: null,
      options_status: [
        { value: '1', text: 'Active' },
        { value: '0', text: 'Inactive' },
      ],
      autogenerateDisable: false,
    }
  },
  mounted() {
    this.isp_id = $('#ispListId option:selected').val()
    this.packageListData(this.isp_id)
    this.routerListData(this.isp_id)
    const instance = this
    $('#ispListId').change(function () {
      this.isp_id = $(this).children('option:selected').val()
      instance.packageListData(this.isp_id)
      instance.routerListData(this.isp_id)
    })
    this.getUserNameData()
  },
  methods: {
    changePackage(package_price, packageData) {
      if (package_price) {
        for (const package_price of package_price) {
          if (package_price.value == packageData) {
            this.usernameInfo.package_price = package_price.price
            this.usernameInfo.package_id = package_price.value
          }
        }
      }
    },
    getUserNameData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + USERNAME_VIEW + this.usernameId)
        .then(res => {
          this.usernameInfo = res.data.data
          this.userData = res.data.data.users
          this.autogenerateDisable = this.usernameInfo.autogenerate != 0
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    packageListData(ispId) {
      this.$http
        .get(
          `${process.env.VUE_APP_BASEURL + ISP_PACKAGE_LIST}?isp_id=${ispId}`,
        )
        .then(res => {
          this.package_list = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    routerListData(ispId) {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL + ISP_ROUTER_LIST}?isp_id=${ispId}`)
        .then(res => {
          this.router_list = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt
            .updateCustomerUserName(this.usernameId, {
              user_id: this.usernameInfo.user_id,
              router_id: this.usernameInfo.router_id,
              package_id: this.usernameInfo.package_id,
              package_price: this.usernameInfo.package_price,
              title: this.usernameInfo.title,
              user_name: this.usernameInfo.user_name,
              autogenerate: this.usernameInfo.autogenerate,
              status: this.usernameInfo.status_value,
              do_not_update_meta: true,
            })
            .then(response => {
              if (checkRes(response.data.code)) {
                this.autogenerateDisable = response.data.data.autogenerate != 0
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage('success', 'Assign Dail Up', response)
                  })
              } else {
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage('warning', 'Assign Dail Up', response)
                  })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    // Make New Invoice
    createNewInvoice() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt
            .createNewInvoice(this.usernameId, {
              isp_id: this.isp_id ?? null,
              user_id: this.usernameInfo.user_id,
              grand_total: this.usernameInfo.package_price,
              next_invoice_date: this.usernameInfo.invoice_date,
              next_due_date: this.usernameInfo.next_due_date,
            })
            .then(response => {
              console.log(response)
              if (checkRes(response.data.code)) {
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage(
                      'success',
                      'Invoice Create Successfully',
                      response,
                    )
                  })
              } else {
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage('warning', 'Invoice', response)
                  })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    // comfirm button color
    confirmDeleteUserNames() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt
            .deleteCustomerUserName({
              user_id: this.usernameId,
            })
            .then(response => {
              if (checkRes(response.data.code)) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$router
                  .push({ name: 'isp-username-list' })
                  .catch(error => {
                    this.toastMessage(
                      'success',
                      'Delete Successfully',
                      response,
                    )
                  })
              } else {
                this.$swal({
                  title: 'Cancelled',
                  text: 'Your imaginary file is safe :)',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              this.$swal({
                title: 'Cancelled',
                text: 'Your imaginary file is safe :)',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
